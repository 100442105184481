import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import { GetUser } from "../../util/AuthLogic";

const getRoutes = (match) => {
  if(GetUser()?.role === "admin") {
    return (
      <>
        <Route path={`${match.url}users`} exact component={asyncComponent(() => import('./users/Users'))} />
        <Route path={`${match.url}users/adduser`} exact component={asyncComponent(() => import('./users/addUserData'))} />
        <Route path={`${match.url}users/edituser`} exact component={asyncComponent(() => import('./users/addUserData'))} />
      </>
    )
  }
}

const Admin = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}applications`} exact component={asyncComponent(() => import('./applications/Applications'))} />
      <Route path={`${match.url}application/:id`} exact component={asyncComponent(() => import('./application/Application'))} />
      {getRoutes(match)}
      {GetUser()?.role === "retailer" ? <Route path={`${match.url}applications/addapplication`} exact component={asyncComponent(() => import('./applications/addApplication'))} /> : ''}
    </Switch>
  </div>
);

export default Admin;
